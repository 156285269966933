import React from "react"
import StandardLayout from "../../layouts/standard"
import coverImage from "../../images/about/our-mission/Code-img.png"

export default function CodeOfConductPage(props) {
  return (
    <StandardLayout title="Code of Conduct">
      <div className="cover-cont">
        <img src={coverImage} alt="" />
        <div>Code of Conduct</div>
      </div>

      <div className="catalog-head">CODE OF CONDUCT</div>

      <section className="uk-section coc-text">
        <div className="uk-container uk-margin-large">
          <h2 className="headind-coc">Introduction</h2>
          <div className="uk-column">
            <p>
              It is believed that it is primary duty of the Board of directors
              to ensure that the Company adheres to all the standards of good
              corporate governance and it is practiced in its true spirit. It is
              said that Charity begins at home, hence all good governance
              practices start from the Board and ends at the Board. Good
              Corporate Governance is aimed to promote and enrich the level of
              honesty and integrity at all levels of Company Management. It is
              also aimed to discourage any kinds of fraud, irregularity and
              malpractices in decision making process and its implementation.
            </p>
            <p>
              The Company - M/s. <strong>Stylam Industries Limited</strong> is
              committed to respect the above stated belief and to follow all
              good standards which lead to value creation and value addition for
              all stake holders and we shall ensure that good governance results
              into well-being of all stake holders.
            </p>
          </div>
          <hr />
          <h2 className="headind-coc">
            Code of Conduct for members of the Board and Senior Management
          </h2>
          <div className="uk-column">
            <p>
              To Comply the <strong> SEBI </strong> (Prohibition of Insider
              Trading) Regulations, 2015 in its letter and spirit and to promote
              good corporate governance in line with the provisions of the SEBI
              (Listing Obligations and Disclosure Requirements) Regulations,
              2015 (<strong>SEBI LODR</strong>), this Code of Conduct is framed
              which shall be known as <strong> Code of Conduct </strong> and
              Ethics ("the Code") for directors and senior officers as defined
              herein under.
            </p>
            <p>
              This Code is intended to focus on all the Board members and senior
              officers on areas of ethics, integrity and honesty, providing
              guidance to help them recognize and deal with ethical issues,
              mechanism to report unethical / dishonest conducts and foster a
              culture of honesty, integrity and accountability.
            </p>
          </div>
          <hr />
          <h2 className="headind-coc">Applicability of the Code</h2>
          <div className="uk-column">
            <p>
              It is believed that it is primary duty of the Board of directors
              to ensure that the Company adheres to all the standards of good
              corporate governance and it is practiced in its true spirit. It is
              said that Charity begins at home, hence all good governance
              practices start from the Board and ends at the Board. Good
              Corporate Governance is aimed to promote and enrich the level of
              honesty and integrity at all levels of Company Management. It is
              also aimed to discourage any kinds of fraud, irregularity and
              malpractices in decision making process and its implementation.
            </p>
            <p>
              The Company - M/s. <strong>Stylam Industries Limited</strong> is
              committed to respect the above stated belief and to follow all
              good standards which lead to value creation and value addition for
              all stake holders and we shall ensure that good governance results
              into well-being of all stake holders.
            </p>
            <p>
              This is a comprehensive code and applies primarily to all the
              Board Members and Senior Officers. Senior Officers means the
              members of Core Management Team, if any framed by the Company, and
              includes all employees one level below the Board including
              functional head of each department and unit. When context requires
              it shall also be applicable to other officers and employees of the
              Company. However, the provisions shall apply to Executive and
              Non-executive directors including independent directors only to
              such extent as may be applicable depending on their respective
              roles and responsibilities.{" "}
            </p>
          </div>
          <hr />
          <h2 className="headind-coc">Conflicts of Interest</h2>
          <div className="uk-column">
            <p>
              The Board Members and Senior Officers as well as employees shall
              always act in the interest of the Company and shall ensure that
              their personal interest is not in conflict with the interest of
              the Company. They shall ensure that any business or personal
              association which they may have does not involve a conflict of
              interest, actual or potential, with the operations of the Company
              and his / her roles therein. To the extent possible they shall not
              enter into a transaction, either directly or indirectly, which
              amounts to related party transactions under the Companies Act,
              2013 and provisions of <strong>SEBI LODR</strong>. However, when
              any such transaction is entered into on the footing that it is for
              the benefit of the Company or otherwise it is necessary to enter
              into such transactions, no such transaction shall be entered
              without complying with all the procedure prescribed under the
              Companies Act, 2013 & SEBI LODR and without disclosing interest
              including nature of interest and they shall not participate in
              that decision making process.
            </p>
          </div>
          <hr />
          <div className="uk-column uk-text">
            A conflict of interest, actual or potential, may arise where,
            directly or indirectly:
          </div>
          <ul class="uk-list uk-column">
            <li class="uk-margin-small-left">
              {" "}
              a. An employee of a company engages in a business, relationship or
              activity with anyone who is party to a transaction with his / her
              Company.
            </li>
            <li class="uk-margin-small-left">
              {" "}
              b. An employee is in a position to derive an improper benefit,
              personally or to any of his/her relatives, by making or
              influencing decisions relating to any transaction.
            </li>
            <li class="uk-margin-small-left">
              {" "}
              c. A judgment of the Company's best interest cannot be exercised.
            </li>
          </ul>
          <div className="uk-column uk-text">
            The main areas of such actual or potential conflicts of interest
            shall include the following:
          </div>
          <ul class="uk-list uk-column">
            <li class="uk-margin-small-left">
              {" "}
              a. An employee or a director of a Company conducting business on
              behalf of his / her Company or being in a position to influence a
              decision with regard to his / her Company's business with a
              supplier or customer where his / her relative is a principal
              officer or representative, resulting in a benefit to him / her or
              his / her relative.
            </li>
            <li class="uk-margin-small-left">
              {" "}
              b. Award of benefits such as increase in salary or other
              remuneration, posting, promotion or recruitment of a relative of
              an employee of a Company, where such an individual is in a
              position to influence decisions with regard to such benefits.
            </li>
            <li class="uk-margin-small-left">
              {" "}
              c. The interest of the Company or the Group can be compromised or
              defeated.
            </li>
          </ul>
          <hr />
          <h2 className="headind-coc">Fair Dealing</h2>
          <div class="uk-column">
            <p>
              Employees and directors should deal fairly with the customers,
              suppliers, competitors and employees of the Group Companies. They
              should not take unfair advantage of anyone through manipulation,
              concealment, abuse of confidential, proprietary or trade secret
              information, misrepresentation of material facts or any other
              unfair dealing practices.
            </p>
          </div>
          <hr />
          <h2 className="headind-coc">Accounting and Financial Reporting</h2>
          <div class="uk-column">
            <p>
              All the books of accounts shall be prepared and maintained fairly
              and accurately in accordance with the accounting and financial
              reporting standards which represent the generally accepted
              guidelines, principles, standards, laws and regulations of the
              country in which the Company conducts its business affairs.
            </p>
            <p>
              The accounting procedures shall fairly and accurately reflect all
              the Company's business transactions and disposition of assets. All
              required information shall be accessible to the Company's Auditors
              and other authorized persons and Government Agencies.
            </p>
            <p>
              All the directors and employees shall ensure that the Company's
              information furnished to the Government Departments / Authorities,
              Financial Institutions and Banks is authentic and accurate.
            </p>
            <p>
              The Company shall comply all the provisions relating to Disclosure
              as stated in the <strong>SEBI LODR</strong>.
            </p>
          </div>
          <hr />
          <h2 className="headind-coc">
            Attendance and Participation in Board Meeting
          </h2>
          <div className="uk-column uk-text">Every Director:</div>
          <ul class="uk-list uk-column">
            <li class="uk-margin-small-left">
              {" "}
              a. Shall make reasonable efforts to attend Board and Committee
              meetings regularly.
            </li>
            <li class="uk-margin-small-left">
              {" "}
              b. Shall apply independent and open mind in various discussion at
              Board or Committee meetings.
            </li>
            <li class="uk-margin-small-left">
              {" "}
              c. Shall share his knowledge and experience on all the agenda
              during the meeting proceedings.
            </li>
          </ul>
          <hr />
          <h2 className="headind-coc">Corporate Business Opportunities</h2>
          <div className="uk-column ">
            In carrying out their duties and responsibilities, employees and
            directors are prohibited from:
          </div>
          <ul class="uk-list uk-column">
            <li class="uk-margin-small-left">
              {" "}
              a. Exploiting for their personal gain opportunities that are
              discovered through the use of Company property, Company
              information or position as a director, unless the opportunity is
              disclosed fully in writing to the Company's Board of directors and
              the Board declines to pursue such opportunity.
            </li>
            <li class="uk-margin-small-left">
              {" "}
              b. Using the Company's property or information for personal gain.
            </li>
            <li class="uk-margin-small-left">
              {" "}
              c. Competing with the Company.
            </li>
          </ul>
          <hr />
          <h2 className="headind-coc">
            Protection and Proper use of Company's Assets
          </h2>
          <div class="uk-column">
            <p>
              All the books of accounts shall be prepared and maintained fairly
              and accurately in accordance with the accounting and financial
              reporting standards which represent the generally accepted
              guidelines, principles, standards, laws and regulations of the
              country in which the Company conducts its business affairs.
            </p>
          </div>
          <hr />
          <h2 className="headind-coc">Confidential Information</h2>
          <div class="uk-column">
            <p>
              Employees and directors should maintain the confidentiality of
              confidential information entrusted to them in carrying out their
              duties and responsibilities, except where disclosure is approved
              by the Company or legally mandated or if such information is
              already in the public domain. Confidential information includes
              all non-public information that might be of use to competitors, or
              harmful to the Company or its customers, if disclosed. The
              Company's confidential information shall not be inappropriately
              disclosed or used for the personal gain or advantage of anyone
              other than the Company. These obligations apply while employed or
              serving as a director of the Company even after employment or the
              director's term with the Company ends.
            </p>
            <p>
              The Board is committed to ensure that the provisions of{" "}
              <strong>SEBI</strong> (Prohibition of Insider Trading)
              Regulations, 2015 are compiled in its true spirit.
            </p>
          </div>
          <hr />
          <h2 className="headind-coc">Compliance with Laws and Regulations</h2>
          <div class="uk-column">
            <p>
              In carrying out their duties and responsibilities, directors and
              employees must comply with applicable laws, rules and regulations.
              In addition, if any director or employee becomes aware of any
              information that he or she believes constitutes evidence of a
              violation of any securities or other laws, rules or regulations
              applicable to the Company or the operation of its business, by the
              Company, any employee or director, then such employee or director
              should bring such information to the attention of the Chairman of
              the Board or such other person as designated in this regard.
            </p>
            <p>
              The Company holds information and training sessions to promote
              compliance with applicable laws, rules and regulations, including
              insider trading laws and it is the responsibility of each director
              and employee to attend such training sessions.
            </p>
          </div>

          <hr />
          <h2 className="headind-coc">
            Encouraging the Reporting of any Illegal or Unethical Behaviour
          </h2>
          <div className="uk-column ">
            Directors should promote ethical behaviour and take steps to ensure
            that the Company:
          </div>
          <ul class="uk-list uk-column">
            <li class="uk-margin-small-left">
              {" "}
              a. Encourages employees to talk to supervisors, managers and other
              appropriate personnel when in doubt about the best course of
              action in particular situation.{" "}
            </li>
            <li class="uk-margin-small-left">
              {" "}
              b. Encourages employees to report violations of laws, rules,
              regulations or the Company's Code of Conduct to the appropriate
              personnel.{" "}
            </li>
            <li class="uk-margin-small-left">
              {" "}
              c. Informs employees that the company will not allow retaliation
              for reports made in good faith.
            </li>
          </ul>
          <p>
            The confidentiality of those reporting violations shall be protected
            and they shall not be subject to any discriminatory practices.
          </p>
          <hr />
          <h2 className="headind-coc">Compliance Standards</h2>
          <ul class="uk-list uk-column">
            <li class="uk-margin-small-left">
              {" "}
              a. All suspected violations of this Code shall be promptly
              reported to the Board and such violations are subject to
              investigation by the board or any committee / or any person(s) so
              designated by the board. Violations will be investigated by the
              board or any such designated persons / committee and appropriate
              action will be taken in the event of any such violations.
            </li>
            <li class="uk-margin-small-left">
              {" "}
              b. All directors should inform the Company immediately about the
              emergence of any situation which may disqualify them from
              continuing as director.
            </li>
            <li class="uk-margin-small-left">
              {" "}
              c. It shall be the endeavour of every director to attend as far as
              possible and actively participate in meetings of the Board and
              Committees thereof of which they are members.
            </li>
          </ul>
          <hr />
          <h2 className="headind-coc">Amendment, Modification & Waivers</h2>
          <div className="uk-column ">
            <p>
              Any amendment to this Code must be approved by the Board of
              directors and publicly disclosed as required by any applicable law
              or regulation.
            </p>
            <p>
              Any waiver of this Code for the benefit of any employees, officer
              or director of the Company may be made only by the Company's Board
              of directors and shall be disclosed promptly as required by
              applicable laws and regulations including the rules of any
              exchange on which the Company's securities are listed or traded.
            </p>
          </div>
        </div>
      </section>
    </StandardLayout>
  )
}
